.linkButton {
  color: #ffffff;
  transition: color 0.3s linear;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 0;
}

.linkButton:hover {
  color: #f77758;
}

.linkButton.active {
  color: #f77758;
}

.profile {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}

.profile .loading {
  color: #ffffff;
}

.profile .container {
  display: flex;
  flex-direction: column;
  min-width: 62rem;
  justify-content: center;
  align-items: center;
}

.profile .userData {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profile .userData .address {
  color: #f77758;
  word-break: break-all;
}

.profile .headerGallery {
  color: #ffffff;
  margin: 5rem 0;
  font-size: 1.2rem;
}

.profile .headerGallery .linkButton {
  font-size: 1.2rem;
}

.profile .gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
  width: 100%;
  grid-gap: 1rem;
  justify-items: center;
}

.profile .gallery .pixelchain {
  width: 128px;
  height: 128px;
}

@media screen and (max-width: 1040px) {
  .profile {
    padding: 0 1rem;
  }

  .profile .container {
    min-width: initial;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .profile {
    flex-direction: column;
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 630px) {
  .profile .userData {
    flex-direction: column;
    align-items: center;
  }

  .profile .gallery {
    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  }

  .profile .gallery .pixelchain {
    width: 256px;
    height: 256px;
  }
}
